import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface DocumentSuccessfullProps {
  open: boolean;
  onClose: () => void;
}

export default function DocumentSuccessfull({
  open,
  onClose,
}: DocumentSuccessfullProps) {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={open}
        // onClose={onClose}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        className="modal-main"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Profile Completed Successfully 
          </Typography>
          <p>Waiting for Admin’s Approval. Our team will respond back, shortly.</p>
          <div className="modal-btn">
            <button
              type="button"
              className="btnn btn_primary big_cursor"
              onClick={() => navigate("/")}
            >
              Go To Home
            </button>
            {/* <figure className="crs_icn" onClick={onClose}>
              <img src="./assets/images/crs_icn.png" alt="logo"></img>
            </figure> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
