import React from "react";
import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import Reserve from "./features/reserve";
import Services from "./features/services";
import Contact from "../../layout/component/contact";
import Applications from "./features/applications";
import InfinitySec from "./features/infinitySec";

const Home = () => {
  const bannerData = {
    videoSrc: "./assets/images/hero.mp4",
    heading: "REDEFINING LUXURY",
    subheading: "",
    description: "Where cutting-edge technology meets unparalleled luxury.",
    showButton: true,
    videoSpeed: 1
  };
  return (
    <Layout>
      <Banner
        videoSpeed={bannerData?.videoSpeed}
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
        extraClass=''
      />
      <Reserve />
      <Services />
      <Applications />
      <InfinitySec />
      <Contact />
    </Layout>
  );
};

export default Home;
