import React, { useEffect, useState } from "react";
import LoginForm from "./featured/login-form";
import LoginContent from "../../../layout/component/login-content";

const LoginPage = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, []);
  const [isFullWidth, setIsFullWidth] = useState(false);

  const handleButtonClick = () => {
    setIsFullWidth(prevState => !prevState); // Toggle the isFullWidth state
  };
  return (
    <>
      <section className={`login-page ${isFullWidth ? 'full-width' : ''}`}>
          <div className="gap_m">
            <LoginContent onButtonClick={handleButtonClick}/>
            {!isFullWidth && <LoginForm />}
            {/* <LoginForm isFullWidth={isFullWidth}/> */}
          </div>
      </section>
    </>
  );
};

export default LoginPage;
