import React from "react";
import { useNavigate } from "react-router-dom";

const Organisation = () => {
  const navigate = useNavigate();
  return (
    <section className="organisation-sec">
      <div className="conta_iner">
        <div className="sb_hd text-white" >
          <h2 data-aos="fade-up"
              data-aos-duration="1000">Careers</h2>
          <p data-aos="fade-up"
              data-aos-duration="2000">
              Join Esqyre , where we treat every team member like family and foster an environment where each role is highly valued and competitively rewarding. Be part of a team that supports growth, respect, and share success.
          </p>
          <button className="btnn btn_white big_cursor" type="button" data-aos="fade-up"
              data-aos-duration="3000">
            <span onClick={() =>
                  navigate("/contact-us", {
                    state: { scrollTo: "callSecPage" },
                  })
                }>Enquire more</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Organisation;
