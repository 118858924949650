import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Carousel = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  return (
    <>
      <Slider {...settings} className="slider-sec">
        <div className="slider-box">
          <figure>
            <img src="./assets/images/slider1.jpg" alt="logo"></img>
          </figure>
        </div>
        <div className="slider-box">
          <figure>
            <img src="./assets/images/slider2.jpg" alt="logo"></img>
          </figure>
        </div>
        <div className="slider-box">
          <figure>
            <img src="./assets/images/slider3.jpg" alt="logo"></img>
          </figure>
        </div>
      </Slider>
    </>
  );
};

export default Carousel;
