import React from "react";

const AboutContent = () => {
  return (
    <section className="about-sec bg_dark">
      <div className="conta_iner">
        <div className="d_flx">
          <div
            className="ab_lhs sb_hd text_white"
            data-aos={window.innerWidth > 768 ? "fade-right" : "fade-up"}
            data-aos-duration="3000"
          >
            <div className="head">
              <span className="heading">Mission, Vision, and Values</span>
              <span className="sub-heading">Our Vision for the Future</span>
            </div>
            <p className="para">
              At Esqyre, we envision a future where luxury meets innovation. Our plans include expanding into full-service AI-powered concierge services, providing seamless experiences for our clients, from luxury car rentals to dining reservations and travel arrangements. Our goal is to set a new standard in luxury services, combining cutting-edge technology with opulence and convenience
            </p>
            {/* <button className="btnn btn_white big_cursor" type="button">
              <span>Book Now</span>
            </button> */}
          </div>
          <div
            className="ab_rhs text_center"
            data-aos={window.innerWidth > 768 ? "fade-left" : "fade-up"}
            data-aos-duration="3000"
          >
            <figure>
              <img src="./assets/images/logo2.svg" alt="logo"></img>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContent;
