import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const Services = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <section className="services-sec bg_dark">
        <div className="conta_iner">
          <div className="sb_hd text_center flx-center">
            <h2>Explore Services</h2>
            <span className="sub-heading">Chauffeur or Personal Driving - Your Journey, Your Choice</span>
            <p className="center-paragraph">
              Whether you prefer the exclusivity of driving yourself or the
              comfort of a professional chauffeur, Esqyre offers an unparalleled
              experience in both. Choose the option that best suits your needs,
              and allow us to handle the rest.
            </p>
          </div>
          <div className="srvc_flx two">
            <div
              className="srvc_box big_cursor"
              data-aos="fade-up"
              data-aos-duration="1000"
              onClick={() =>
                  navigate("/service", {
                    state: { scrollTo: "individual" },
                  })
                }
            >
              <figure>
                <img
                  src="./assets/images/self-drive-rental.png"
                  alt="service"
                ></img>
              </figure>
              <div
                className="srvc_hdng"
                
              >
                <h3>Self-Drive Rentals</h3>
              </div>
            </div>
            <div
              className="srvc_box big_cursor"
              data-aos="fade-up"
              data-aos-duration="2000"
              onClick={() =>
                navigate("/service", {
                  state: { scrollTo: "corp" },
                })
              }
            >
              <figure>
                <img
                  src="./assets/images/chauffer-rental.png"
                  alt="service"
                ></img>
              </figure>
              <div className="srvc_hdng">
                <h3>Chauffer Rentals </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
