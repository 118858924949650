import React from "react";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();
  return (
    <section className="bg_dark sprt_sc">
      <div className="sb_hd text_white text_center mb_50">
        <h2>We are ready to assist you</h2>
      </div>
      <div className="conta_iner">
        <div className="d_flx">
          <div className="support-lhs sb_hd text_center">
            <h2 data-aos="fade-up" data-aos-duration="1000">
              Need Support ?
            </h2>
            <p data-aos="fade-up" data-aos-duration="2000">
              Our support team is waiting to provide solutions. Get in touch and let us take care of the rest.
            </p>
            <button
              type="button"
              className="btnn btn_white big_cursor"
              data-aos="fade-up"
              data-aos-duration="3000"
              onClick={() => window.scrollBy({ top: 500, behavior: "smooth" })}
            >
              Go To Support
            </button>
          </div>
          <div className="support-rhs sb_hd text_center">
            <h2 data-aos="fade-up" data-aos-duration="1000">
              Have Questions ?
            </h2>
            <p data-aos="fade-up" data-aos-duration="2000">
              Our FAQ section has everything you need! Find quick and detailed
              answers to questions.
            </p>
            <button
              className="btnn btn_white big_cursor"
              type="button"
              data-aos="fade-up"
              data-aos-duration="3000"
              onClick={() => navigate("/faq")}
            >
              <span>Go to Faq</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
