import React from "react";
import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import AboutContent from "./features/experience";
import ServiceBanner from "../../layout/component/service-banner";
import Contact from "../../layout/component/contact";

const Infinity = () => {
  return (
    <Layout>
      <AboutContent />
    </Layout>
  );
};

export default Infinity;
