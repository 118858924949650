import React from "react";
import Slider from "react-slick";

import "aos/dist/aos.css";
const Reserve = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000
  };
  return (
    <>
      <section className="reserve-sec">
        <div className="conta_iner">
          <div className="rs_hd">
            <h2 className="resrv-heading" data-aos="fade-up" data-aos-duration="2000">
              Choose Your Luxury Experience
            </h2>
            <p data-aos="fade-up" data-aos-duration="2000" className="text-paragraph">
            At Esqyre, we offer three distinct tiers of luxury to ensure every journey is tailored to perfection. From our hand-picked Premium vehicles to the utmost exclusivity of Esqyre Reserve, your experience will be nothing less than extraordinary.
            </p>
            <div data-aos="fade-up" data-aos-duration="2000" className="slider-container">
              <Slider {...settings} className="slider-sec">
                <div className="slider-box">
                  <h6 className="reserve-heading">Esqyre Reserve</h6>
                  <p className="reserve-desc">
                    The ultimate in exclusivity. Opulent and rare vehicles for those who accept nothing but the best.
                  </p>
                </div>
                <div className="slider-box">
                  <h6 className="reserve-heading">Super Premium</h6>
                  <p className="reserve-desc">Experience luxury and performance in our curated selection of high-end vehicles. Perfect for any occasion requiring sophistication.</p>
                </div>
                <div className="slider-box">
                  <h6 className="reserve-heading">Premium</h6>
                  <p className="reserve-desc">Stylish, luxurious, and comfortable. Our Premium vehicles are perfect for those looking for an exceptional experience without compromise.</p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reserve;
