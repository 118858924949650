import React from 'react'
import "./App.scss";
import Routes from './Routes';

const App = () => {
  return (
    <Routes/>
  )
}

export default App