import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/store";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { usePostSignUpMutation, useVendorPostSignUpMutation } from "../../services/auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showError } from "../../constants/toast";
import Loader from "../../constants/Loader";
import { removeFromStorage, setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { resetAuth } from "../../reducers/authSlice";

type props = {
    person: string;
}

const SignUp = ({ person }: props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [signUpApi] = usePostSignUpMutation();
    const [signUpApiVendor] = useVendorPostSignUpMutation();

    const handleClickShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required("Email is required")
                .matches(
                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    "Please enter a valid email"
                )
                .max(80, "Email must be less than or equal to 80 characters"),
            password: Yup.string()
                .label("new Password")
                .required("Password is required")
                .matches(
                    /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[0-9])[^\s]{8,}$/,
                    "Password must be at least 8 characters long and include 1 uppercase, 1 lowercase, and 1 special character."
                ),

            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords must match")
                .required("Confirm password is required"),
        }),
        onSubmit: async (values) => {
            formik.setSubmitting(true);
            let body = {
                email: formik.values.email,
                password: formik.values.password,
                confirmPassword: formik.values.confirmPassword,
                role: person === "vendor" ? 4 : 5,
            }
            setIsLoading(true)
            console.log("body for sign up", body);
            if (person === "vendor") {
                try {
                    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
                    const response = await signUpApiVendor(encryptedBody).unwrap();
                    setIsLoading(false)
                    if (response?.statusCode !== 200) {
                        showError(response?.message);
                        return;
                    }

                    navigate("/otp", { state: { email: formik.values.email, person: person }, });
                    resetAuth()
                    removeFromStorage(STORAGE_KEYS.token)
                } catch (error: any) {
                    setIsLoading(false)
                    showError(error?.data?.message || "");
                }
            } else {
                try {
                    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
                    const response = await signUpApi(encryptedBody).unwrap();
                    setIsLoading(false)
                    if (response?.statusCode !== 200) {
                        showError(response?.message);
                        return;
                    }
                    navigate("/otp", { state: { email: formik.values.email, person: person } });
                } catch (error: any) {
                    setIsLoading(false)
                    showError(error?.data?.message || "");
                }
            }
        },
    });

    useEffect(() => {
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.user)
        resetAuth();
    }, []);

    return (
        <>
            <Loader isLoad={isLoading} />
            <div className="login-box">
                <div className="login-heading">
                    <h2 className="heading-font">Hello</h2>
                    <p>Create an Account as {person === "vendor" ? "a Vendor" : "Corporate Admin"}</p>
                </div>
                <form className="form" onSubmit={formik.handleSubmit}>
                    <div className="control_group">
                        {/* <FormControl>
                    <RadioGroup
                      className="checkbox_label"
                      aria-labelledby="radio-type-label"
                      defaultValue="buy"
                      name="radio-type"
                    >
                      <FormControlLabel
                        value="Customer"
                        control={<Radio />}
                        label="Customer"
                      />
                      <FormControlLabel
                        value="Corporate"
                        control={<Radio />}
                        label="Corporate Admin"
                      />
                    </RadioGroup>
                  </FormControl> */}
                    </div>
                    <div className="control_group">
                        <TextField
                            hiddenLabel
                            placeholder="Email"
                            fullWidth
                            variant="outlined"
                            name="email"
                            type="email"
                            id="email"
                            inputProps={{ maxLength: 80 }}
                            onChange={(val) => {
                                if (val.target.value === " " || val.target.value === ".") {
                                } else {
                                    formik.handleChange(val);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            helperText={formik.touched.email && formik.errors.email}
                            error={
                                formik.touched.email && Boolean(formik.errors.email)
                            }
                        />
                    </div>
                    <div className="control_group">
                        <TextField
                            hiddenLabel
                            placeholder="Password"
                            fullWidth
                            // placeholder="Enter Password"
                            variant="outlined"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.password && Boolean(formik.errors.password)
                            }
                            helperText={
                                formik.touched.password && formik.errors.password
                            }
                            inputProps={{ maxLength: 50 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="eye_btn" position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword()}
                                            onMouseDown={(e) => handleMouseDownPassword(e)}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="control_group">
                        <TextField
                            hiddenLabel
                            placeholder="Confirm Password"
                            fullWidth
                            variant="outlined"
                            name="confirmPassword"
                            type={showPassword2 ? "text" : "password"}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.confirmPassword &&
                                Boolean(formik.errors.confirmPassword)
                            }
                            helperText={
                                formik.touched.confirmPassword &&
                                formik.errors.confirmPassword
                            }
                            inputProps={{ maxLength: 50 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="eye_btn" position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword2()}
                                            onMouseDown={(e) => handleMouseDownPassword(e)}
                                            edge="end"
                                        >
                                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="form_btn text_center">
                        <Button
                            className="btnn btn_primary big_cursor"
                            type="submit"
                        // onClick={() => navigate("/profile-setup")}
                        >
                            Create Account
                        </Button>
                    </div>
                </form>
                {/* <div className="or-box">
                    <span>OR</span>
                </div>
                <div className="form_btn text_center">
                    <Button className="btnn btn_primary br big_cursor">
                        Register with UAE PASS
                    </Button>
                </div> */}
            </div>
        </>
    )
}

export default SignUp;