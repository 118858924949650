import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

type props = {
  videoSrc: string;
  heading: string;
  subheading: string;
  description: any;
  showButton: boolean;
  extraClass: string;
  videoSpeed?: number
};

const Banner = ({
  videoSrc,
  heading,
  subheading,
  description,
  showButton,
  extraClass,
  videoSpeed
}: props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    AOS.init({});
    if (videoRef.current) {
      videoRef.current.playbackRate = videoSpeed || 1; // Set video speed
    }
  }, []);

  return (
    <>
      <section className={`outter hero-video ${extraClass}`}>
        <section className="video-container">
          <video ref={videoRef} src={videoSrc} autoPlay loop playsInline muted></video>
          <div className="callout">
            <h1 data-aos="fade-up" data-aos-duration="1000">
              {heading || ""}
              <br />
              {subheading || ""}
            </h1>
            <p className={extraClass}
              data-aos="fade-up"
              data-aos-duration="2000"
              dangerouslySetInnerHTML={{
                __html: description ? description : " ",
              }}
            ></p>
            {showButton ? (
              <button
                className="btnn btn_white big_cursor"
                type="button"
                data-aos="fade-up"
                data-aos-duration="3000"
                onClick={() =>
                  window.scrollBy({ top: 700, behavior: "smooth" })
                }
              >
                <span>Explore</span>
              </button>
            ) : null}
          </div>
        </section>
      </section>
    </>
  );
};

export default Banner;
