import React from "react";

const ChooseEsqare = () => {
  return (
    <section className="bg_dark why_chs">
      <div className="conta_iner">
        {/* <div className="sb_hd text_white text_center mb_50">
          <h2>Mission, Vision, and Values </h2>
          <p>Our Vision for the Future</p>
          <p className="paragraph-center">
            At Esqyre, we envision a future where luxury meets innovation. Our
            plans include expanding into full-service AI-powered concierge
            services, providing seamless experiences for our clients, from
            luxury car rentals to dining reservations and travel arrangements.
            Our goal is to set a new standard in luxury services, combining
            cutting-edge technology with opulence and convenience
          </p>
        </div> */}
        <div className="sb_hd text_white text_center mb_50">
          <h2>Our Core Values</h2>
        </div>

        <div className="d_flx mt_40">
          <div className="pick-up" data-aos="fade-up" data-aos-duration="1000">
            <h3>Uncompromising Quality</h3>
            <p>
              We are committed to providing only the highest-quality vehicles
              and services, ensuring every journey exceeds expectations.
            </p>
          </div>
          <div className="pick-up" data-aos="fade-up" data-aos-duration="2000">
            <h3>Client-Centric Approach</h3>
            <p>
              Our clients are at the heart of everything we do. We focus on
              providing a personalized, white-glove experience that caters to
              individual needs and desires.
            </p>
          </div>
          <div className="pick-up" data-aos="fade-up" data-aos-duration="3000">
            <h3>Innovation in Luxury</h3>
            <p>
              We believe in redefining what luxury means through innovation. By
              integrating technology like AI-powered concierge services, we aim
              to elevate the luxury experience to new heights.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseEsqare;
