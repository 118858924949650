import React from "react";
import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import AboutContent from "./features/experience";
import ServiceBanner from "../../layout/component/service-banner";
import Contact from "../../layout/component/contact";

const Service = () => {
  const bannerData = {
    videoSrc: "./assets/images/service.mp4",
    heading: "OUR Services",
    subheading: "",
    description: "‘Where every Journey is a Symphony of Luxury’ - Crafted for those who Expect Nothing But the Best",
    showButton: false,
    videoSpeed: 1.45
  };
  return (
    <Layout>
      <Banner
        videoSpeed={bannerData?.videoSpeed}
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
        extraClass='service-page'
      />
      <AboutContent />
      <Contact />
    </Layout>
  );
};

export default Service;
