import React, { useState } from 'react'
import Header from "./header/Header";
import Footer from "./footer/Footer";
import SidePanel from './sidepanel';

const Layout = ({ children }: { children: any }) => {

  const [show,setShow]= useState(false)

  console.log(show,"show");
  
    return (
      <>
        <Header show={show} setShow={setShow}  />
        <SidePanel  show={show} setShow={setShow}/>
        {children}
        <Footer />
      </>
    );
  };

export default Layout