import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const InfinitySec = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <section className="infinity-sec drk-bg-sec drk-bg-sec_ryt">
        <div className="conta_iner">
          <div className="infinity-container" data-aos="fade-up" data-aos-duration="3000">
            <div className="head">
              <span className="heading">Esqyre <span className="gradient-header">Infinity</span></span>
              <span className="sub-heading">The Ultimate Vision of Luxury.</span>
            </div>
            <p className="paragraph">
              Discover a world where luxury knows no limits. Esqyre Infinity is our promise to redefine exclusivity and elevate every experience. Coming soon.
            </p>
            <button onClick={() => navigate("/infinity")} className="btnn btn_white big_cursor" type="button">
              <span>Learn More</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default InfinitySec;
