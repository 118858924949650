import Layout from "../../layout";
import LandingPage from "./features/landingPage";

const Vendor = () => {
  return (
      <Layout>
        <LandingPage />
      </Layout>
    );
};

export default Vendor;

