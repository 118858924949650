import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLazyGetFaqQuery } from "../../../services/cms";
import { showError } from "../../../constants/toast";
import Loader from "../../../constants/Loader";
import { Box, Tab, Tabs, Typography } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Questions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [wantMoreSupport, openSupport] = useState<boolean>(false);
  const [getFaq] = useLazyGetFaqQuery();
  const [faqData, setFaqData] = useState<any>([]);
  const [value, setValue] = useState<number>(0);
  const handleSupportClick = () => {
    openSupport(!wantMoreSupport);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async (value: number) => {
    setIsLoading(true);
    let role: number = 3;
    if (value === 0) {
      role = 5;
    } else if (value === 1) {
      role = 4;
    } else if (value === 2) {
      role = 3;
    } else {
      role = 8;
    }
    try {
      const res = await getFaq({ role }).unwrap();
      if (res?.statusCode === 200) {
        setFaqData(res?.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      showError(error?.data?.message);
      setIsLoading(false);
    }
  };

  console.log(faqData, "faq");

  useEffect(() => {
    fetchData(value);
  }, [value]);

  return (
    <>
      <section className="bg_dark pb_0">
        <Loader isLoad={isLoading} />
        <div className="conta_iner">
          <div className="sb_hd text_center">
            <h2>All Your Car Rental Questions, One Click Away! </h2>
            {/* <p>Answers to Your Questions</p> */}
          </div>
          <div className="faq-accordion">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Corporate Rentals" {...a11yProps(0)} />
                  <Tab label="Vendor Partnerships" {...a11yProps(1)} />
                  <Tab label="Individual Rentals" {...a11yProps(2)} />
                  {/* <Tab label="Chauffer" {...a11yProps(3)} /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {/* <div className="sb_hd">
                <p className="">Individual Rentals</p>
              </div> */}

                {faqData?.length ? (
                  faqData?.map((item: any, index: number) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {index + 1}. {item?.question}
                        </AccordionSummary>
                        <AccordionDetails>{item?.answer}</AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <img
                    className="nodata"
                    src="./assets/images/noDataFound.png"
                    alt="img"
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {/* <div className="sb_hd">
                <p className="">Vendor Partnerships</p>
              </div> */}
                {faqData?.length ? (
                  faqData?.map((item: any, index: number) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {index + 1}. {item?.question}
                        </AccordionSummary>
                        <AccordionDetails>{item?.answer}</AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <img
                    className="nodata"
                    src="./assets/images/noDataFound.png"
                    alt="img"
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {/* <div className="sb_hd">
                <p className="">Corporate Rentals</p>
              </div> */}
                {faqData?.length ? (
                  faqData?.map((item: any, index: number) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {index + 1}. {item?.question}
                        </AccordionSummary>
                        <AccordionDetails>{item?.answer}</AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <img
                    className="nodata"
                    src="./assets/images/noDataFound.png"
                    alt="img"
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                {faqData?.length ? (
                  faqData?.map((item: any, index: number) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {index + 1}. {item?.question} ?
                        </AccordionSummary>
                        <AccordionDetails>{item?.answer}</AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <img
                    className="nodata"
                    src="./assets/images/noDataFound.png"
                    alt="img"
                  />
                )}
              </CustomTabPanel>
              <Accordion onClick={() => handleSupportClick()}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >LOOKING  FOR MORE SUPPORT ?</AccordionSummary>
              </Accordion>
            </Box>
          </div>

        </div>
      </section>
      {wantMoreSupport && <section className="contact-faq-sec dark_bg">
        <span className="sub-heading">
          Submit your details, and our representatives will reach out shortly.
        </span>
        <div
          className="sb_hd text_center frm-container"
          style={{
            marginBottom: "50px",
          }}
        >

          <div className="form-section">
            <input type="text" placeholder="Full Name" className="form-control" />
            <input type="text" placeholder="Phone No" className="form-control" />
            <input type="email" placeholder="Email Id" className="form-control" />
            <textarea className="form-control" placeholder="Message"></textarea>
          </div>
          <button className="btnn btn_white big_cursor" type="button">
            <span>SUBMIT</span>
          </button>
        </div>
      </section>}
    </>
  );
};

export default Questions;
