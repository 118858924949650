import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const InfinityFirstSec = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <section className="infinity-first-sec">
        <div className="conta_iner">
          <div className="top-left">
            <div className="infinity-container" data-aos="fade-up" data-aos-duration="3000">
              <div className="head">
                <span className="heading">Esqyre <span className="gradient-header">Infinity</span></span>
                <span className="sub-heading">The Future of Luxury, Redefined</span>
              </div>
              <p className="paragraph">
                Esqyre Infinity is our vision of the ultimate luxury experience—seamlessly integrating travel, lifestyle, and technology into a single, unparalleled service.
              </p>
            </div>
          </div>
          <div className="top-right">
            <figure>
              <img src="./assets/images/infinity-circle.gif" alt="ESQYRE INFINITY GIF"></img>
            </figure>
          </div>
        </div>
      </section>
      <section className="infinity-sec second-sec drk-bg-sec drk-bg-sec_ryt">
        <div className="just-bg-shadow"></div>
        <div className="conta_iner">
          <div className="infinity-container" data-aos="fade-up" data-aos-duration="3000">
            <div className="head">
              <span className="sub-heading">More Than a Car Rental Company</span>
            </div>
            <p className="paragraph">
              Esqyre Infinity represents the next generation of luxury services. We are building a platform that combines cutting-edge AI technology with a human touch to deliver a holistic experience—whether it’s reserving exclusive vehicles, coordinating premium travel, or curating high-end lifestyle experiences.
            </p>
          </div>
        </div>
      </section>
      <section className="infinity-sec third-sec">
        <div className="conta_iner">
          <div className="infinity-container" data-aos="fade-up" data-aos-duration="3000">
            <div className="head">
              <span className="heading">What to Expect from Esqyre <span className="gradient-header">Infinity</span></span>
            </div>
            <div className="grid-group">
              <div className="grid">
                <div className="first">
                  <span className="sub-heading">AI-Powered Concierge Services</span>
                  <p className="paragraph">
                    Automating bookings for vehicles, dining, travel, and more—anticipating your every need.
                  </p>
                </div>
                <div className="second">
                  <span className="sub-heading">Seamless Integration</span>
                  <p className="paragraph">
                    A single platform for managing luxury lifestyle experiences across all domains.
                  </p>
                </div>
              </div>
              <div className="grid">
                <div className="third">
                    <span className="sub-heading">Unmatched Personalization</span>
                    <p className="paragraph">
                      Bespoke services tailored to individual preferences and corporate needs.
                    </p>
                  </div>
                  <div className="fourth">
                    <span className="sub-heading">Exclusive Access</span>
                    <p className="paragraph">
                      Reserved for those who demand the extraordinary—vehicles, destinations, and experiences beyond imagination
                    </p>
                  </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section className="infinity-fourth-sec drk-bg-sec">
        <div className="conta_iner">
          <div className="top-left">
            <div className="infinity-container" data-aos="fade-up" data-aos-duration="3000">
              <div className="head">
                <span className="sub-heading">The Journey Begins Here</span>
              </div>
              <p className="paragraph">
                Esqyre Infinity is in development, with our team working tirelessly to bring this unparalleled vision to life. While our current services set the standard in luxury, Infinity will redefine it completely. Stay tuned as we craft the future of exclusive, high-end living.
              </p>
            </div>
            <div className="form-section">
              <span className="sub-heading">Signup for updates</span>
              <div className="single-rw">
                <input type="text" className="form-control w50" placeholder="Enter First Name"/>
                <input type="text" className="form-control w50" placeholder="Enter Last Name"/>
              </div>
              <input type="email" className="form-control" placeholder="Enter Email"/>
            </div>
            <button className="btnn btn_white big_cursor" type="button">
              <span>SUBMIT</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default InfinityFirstSec;
