import React, { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

type props = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};

const SidePanel = ({ show, setShow }: props) => {
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <div>
      <ul className={show ? "show_sidebar sidebar-box" : "sidebar-box"}>
        <li className="close_sidebar" onClick={() => setShow(false)}>
          <figure>
            <img src="./assets/images/cross.svg" alt="logo"></img>
          </figure>
        </li>
        <li onClick={() => navigate("/")} className="big_cursor">
          Home
        </li>
        <li onClick={() => navigate("/about")} className="big_cursor">
          About Us
        </li>
        <li
          onClick={() => setOpenDropdown(!openDropdown)}
          className="big_cursor"
        >
          Services {openDropdown ? <ExpandLess /> : <ExpandMore />}
          {openDropdown && (
            <ul className="big_cursor">
              <li
                onClick={() => navigate("/service")}
                className="big_cursor menuitem"
              >
                Individual & Corporate Rentals
              </li>
              <li
                onClick={() => navigate("/become-a-vendor")}
                className="big_cursor menuitem"
              >
                Corporate Portal & Apps
              </li>
            </ul>
          )}
        </li>
        <li onClick={() => navigate("/login?vendor")} className="big_cursor">
          Become A Vendor
        </li>
        <li onClick={() => navigate("/contact-us")} className="big_cursor">
          Contact Us
        </li>
        <li onClick={() => navigate("/faq")} className="big_cursor">
          FAQ'S
        </li>
      </ul>
      <div
        className={show ? "show_sidebar otsd_clcl" : "otsd_clcl"}
        onClick={() => setShow(false)}
      >
        <span className="close_sidebar"></span>
      </div>
    </div>
  );
};

export default SidePanel;
