import React from "react";
import Banner from "../../layout/component/banner";
import Layout from "../../layout";
import Contact from "../../layout/component/contact";
import Content from "./features/content";
import { useLocation } from "react-router-dom";

const Terms = () => {

  const location = useLocation();
  const { state } = location;
  const bannerData = {
    videoSrc: "./assets/images/faq.mp4",
    heading: "Terms & Conditions",
    subheading: "",
    description:"Terms & Conditions",
    showButton: false,
  };
  return (
    <Layout>
      <Banner
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
        extraClass='term'
      />
      <Content />
      <Contact />
    </Layout>
  );
};

export default Terms;
