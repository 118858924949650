import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Pages from './pages';
import LoginPage from './pages/auth/login';
import ProfileSetup from './pages/auth/profile-setup';
import DocumentProof from './pages/auth/document-proof';
import OTPPage from './pages/auth/otp';


const Routing = () => {
  return (
    <Routes>
      <Route path='/' element={<Pages.Home />} />
      <Route path='/about' element={<Pages.About />} />
      <Route path='/service' element={<Pages.Service />} />
      <Route path='/infinity' element={<Pages.Infinity />} />
      <Route path='/become-a-vendor' element={<Pages.Vendor />} />
      <Route path='/contact-us' element={<Pages.ContactUs />} />
      <Route path='/privacy' element={<Pages.PrivacyPolicy />} />
      <Route path='/terms' element={<Pages.Terms />} />
      <Route path='/cookies' element={<Pages.Cookies />} />
      <Route path='/faq' element={<Pages.Faq />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/otp' element={<OTPPage />} />
      <Route path='/profile-setup' element={<ProfileSetup />} />
      <Route path='/document-upload' element={<DocumentProof />} />
      <Route path='/file-summarization' element={<Pages.FileSummarizationPage />} />
    </Routes>
  )
}
export default Routing