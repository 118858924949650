import {
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cross from "../../../../layout/component/cross-icon";
import SignIn from "../../../../features/auth/signIn";
import SignUp from "../../../../features/auth/signUp";
import { removeFromStorage } from "../../../../constants/storage";
import { resetAuth } from "../../../../reducers/authSlice";
import { STORAGE_KEYS } from "../../../../constants/storageKeys";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const LoginForm = () => {
  const [value, setValue] = React.useState(0);
  const [person, setPerson] = useState<string>("");
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const location = useLocation();
  const { state ,search} = location;
  console.log(state, "state in login page");

  useEffect(() => {
    if (state === "vendor"  || search === "?vendor") {
      setValue(1);
      setPerson("vendor")
    } else {
      setPerson("corporate")
    }
  }, [])

  useEffect(() => {
    removeFromStorage(STORAGE_KEYS.token);
    removeFromStorage(STORAGE_KEYS.user)
    resetAuth();
  }, []);

  return (
    <>
      <div className="login_rhs w_50">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="tab-area"
            >
              <Tab label="Log in" {...a11yProps(0)} />
              <Tab label="Register" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <SignIn person={person} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <SignUp person={person} />
          </CustomTabPanel>
          <Cross />
        </Box>
      </div>
    </>
  );
};

export default LoginForm;
