import React from "react";
import { useNavigate } from "react-router-dom";

const Cross = () => {
  const navigate = useNavigate();
  return (
    <>
      <figure className="crs_icn" onClick={() => navigate("/")}>
        <img src="./assets/images/crs_icn.png" alt="logo"></img>
      </figure>
    </>
  );
};

export default Cross;
